import { useRecheck } from '../../context/recheckContext';
import RecheckSessionsList from './RecheckSessionsList';
import ToggleSwitch from './ToggleSwitch';
import usePagination from '../../hooks/usePagination';

const RecheckSessions = () => {
	const {
		sessions: allSessions,
		currentPage,
		setCurrentPage,
		itemsPerPage,
		setItemsPerPage,
		setFilter,
		showCompletedSessions,
		showClosedSessions,
		setShowCompletedSessions,
		setShowClosedSessions,
		setDateTimeFilter,
		handleSort,
	} = useRecheck();
	const { handleRowsPerPageChange, handlePageChange } = usePagination();

	const handleFilterChange = (name, value) => {
		setCurrentPage(1);
		setItemsPerPage(10);
		setFilter({ name, value });
	};

	const handleDateTimeFilterChange = (start, end, field = 'datetime') => {
		setCurrentPage(1);
		setItemsPerPage(10);
		setDateTimeFilter({ start, end, field });
	};

	return (
		<div className='container-fluid'>
			<ToggleSwitch
				id='showCompletedSessionsSwitch'
				checked={showCompletedSessions}
				onChange={() => setShowCompletedSessions(!showCompletedSessions)}
				label='Include Complete Sessions'
			/>
			<ToggleSwitch
				id='showInvalidSessionsSwitch'
				checked={showClosedSessions}
				onChange={() => setShowClosedSessions(!showClosedSessions)}
				label='Include Closed Sessions'
			/>
			<RecheckSessionsList
				itemsPerPage={itemsPerPage}
				sessions={allSessions?.results || []}
				totalRows={allSessions?.count}
				currentPage={currentPage}
				onPageChange={(page) => {
					setCurrentPage(page);
					handlePageChange(page);
				}}
				onRowsPerPageChange={(newRowsPerPage) => {
					setItemsPerPage(newRowsPerPage);
					handleRowsPerPageChange(newRowsPerPage);
				}}
				onFilterChange={handleFilterChange}
				onSort={handleSort}
				onDateTimeFilterChange={handleDateTimeFilterChange}
			/>
		</div>
	);
};

export default RecheckSessions;
