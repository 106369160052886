import { createContext, useContext, useState, useEffect } from 'react';
import { getAllSessions } from '../api/recheckFunctions';
import useSort from '../hooks/useSort';

const RecheckContext = createContext();

export const useRecheck = () => {
	return useContext(RecheckContext);
};

export const RecheckProvider = ({ children }) => {
	const [sessions, setSessions] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage, setItemsPerPage] = useState(10);
	const [filter, setFilter] = useState(null);
	const [showCompletedSessions, setShowCompletedSessions] = useState(false);
	const [showClosedSessions, setShowClosedSessions] = useState(false);
	const [dateTimeFilter, setDateTimeFilter] = useState({
		start: null,
		end: null,
		field: null,
	});
	const { sortField, sortDirection, handleSort } = useSort();
	const [shouldFetchAllResults, setShouldFetchAllResults] = useState(true);

	const fetchSessions = async () => {
		const apiFilters = {};

		if (shouldFetchAllResults === false) {
			apiFilters.show_completed = showCompletedSessions;
			apiFilters.show_closed = showClosedSessions;

			if (sortField) {
				apiFilters.sort =
					sortDirection === 'desc' ? `-${sortField}` : sortField;
			}

			if (filter?.name && filter?.value) apiFilters[filter.name] = filter.value;
			if (dateTimeFilter) {
				apiFilters[`start_${dateTimeFilter.field}`] = dateTimeFilter.start;
				apiFilters[`end_${dateTimeFilter.field}`] = dateTimeFilter.end;
			}
			apiFilters.page = currentPage;
			apiFilters.page_size = itemsPerPage;
		}

		const res = await getAllSessions(
			apiFilters,
			showCompletedSessions,
			showClosedSessions,
			currentPage,
			itemsPerPage,
			dateTimeFilter,
		);
		setSessions(res);
		setShouldFetchAllResults(false);
	};

	useEffect(() => {
		fetchSessions();
	}, [
		currentPage,
		itemsPerPage,
		filter,
		showCompletedSessions,
		showClosedSessions,
		dateTimeFilter,
		sortField,
		sortDirection,
	]);

	return (
		<RecheckContext.Provider
			value={{
				sessions,
				setSessions,
				currentPage,
				setCurrentPage,
				itemsPerPage,
				setItemsPerPage,
				setFilter,
				filter,
				showClosedSessions,
				setShowClosedSessions,
				showCompletedSessions,
				setShowCompletedSessions,
				dateTimeFilter,
				setDateTimeFilter,
				sortField,
				sortDirection,
				handleSort,
				fetchSessions,
			}}
		>
			{children}
		</RecheckContext.Provider>
	);
};
